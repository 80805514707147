import { motion, AnimatePresence, LayoutGroup } from "framer-motion";
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState, useContext } from 'react';
import { Grid, Skeleton, Container, Pagination } from '@mantine/core';
//import firebase from 'gatsby-plugin-firebase';
import UserContext from '../../contexts/UserContext';
import SettingsContext from '../../contexts/SettingsContext';
import CreateResume from '../../components/dashboard/CreateResume';
import LoadingScreen from '../../components/router/LoadingScreen';
import ResumePreview from '../../components/dashboard/ResumePreview';
import TopNavbar from '../../components/dashboard/TopNavbar';

const Dashboard = ({ user }) => {
  const { t } = useTranslation();
//  const [resumes, setResumes] = useState([]);
  const [loading, setLoading] = useState(false);

  const { resumes, getUsersBoardDocuments } = useContext( UserContext )

  const container = {
    hidden: { opacity: 1, scale: 1 },
    show: {
      opacity: 1,
      scale: 1,
      transition: {
        delayChildren: .5,
        staggerChildren: .5,
      }
    }
  };

  const item = {
    hidden: { y: 40, opacity: 0 },
    show: i => ({
      y: 0,
      opacity: 1,
      transition: {
        delay: i * .12,
        duration: .16,
        type: "spring",
        bounce: .71,
        stiffness: 350,
        damping: 120,
      }
    })
  };

  useEffect(() => {
    const resumesRef = 'resumes';
    const socketRef = '/.info/connected';

    setLoading(true);
    getUsersBoardDocuments().then((result)=>{
      console.log("loading result -> ", result);
      setLoading( false );
    });

    return () => {};
  }, []);

  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <div>
      <Helmet>
        <title>
          {t('dashboard.title')} | {t('shared.appName')}
        </title>
        <link rel="canonical" href="https://document.boardsi.com/app/dashboard" />
      </Helmet>

      <TopNavbar />

      <div className="mt-12 xl:px-0 mx-auto">
        <motion.div layout initial="hidden" animate="show" variants={container}>
          <Container size={"fluid"}>
            <AnimatePresence>
              <LayoutGroup>
                <Grid gutter={"xl"}>
                  <Grid.Col xs={6} sm={6} md={4} lg={3} xl={2} style={{ paddingBottom: "6rem" }}>
                    <CreateResume />
                  </Grid.Col>
                    {loading ? [ 0, 0, 0 ].map( ( x, i, a ) => ( <>{i}</> ) ) : resumes.map( ( x, i, a ) => (
                      <Grid.Col key={( i + 2 )} xs={6} sm={6} md={4} lg={3} xl={2} style={{paddingBottom: "6rem"}}>
                        <motion.div layout key={( i + 2 )} custom={i} initial="hidden" animate="show" variants={item} >
                          <ResumePreview key={x.id} resume={x} />
                        </motion.div>
                      </Grid.Col>
                    ))}
                </Grid>
              </LayoutGroup>
            </AnimatePresence>
          </Container>
        </motion.div>
      </div>
      <div className="mt-16 mx-auto justify-center items-center">
        <Pagination position="center" total={5} size="lg" />;
      </div>
    </div>
  );
};

export default Dashboard;

export async function getServerData() {}
import { Trash, Copy, EditCircle, DotsCircleHorizontal, FileSymlink, Check, Tags, Share, Certificate } from 'tabler-icons-react';
import { navigate } from 'gatsby';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import React, { useContext, useState } from 'react';
import { Menu, Divider, Text, createStyles, ActionIcon, Popover, useMantineTheme, Dialog, Group, Button, Tooltip } from '@mantine/core';
import { useNotifications } from '@mantine/notifications';
import { useClipboard } from '@mantine/hooks';
import dayjs from 'dayjs';
import DatabaseContext from '../../contexts/DatabaseContext';
import ModalContext from '../../contexts/ModalContext';
import * as styles from './ResumePreview.module.css';

const menuToggleDataTestIdPrefix = 'resume-preview-menu-toggle-';

const ResumePreview = ({ resume }) => {
  const { t, i18n } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const { emitter, events } = useContext(ModalContext);
  const { duplicateResume, deleteResume } = useContext(DatabaseContext);

  const [ openSharePop, setOpenSharePop ] = useState(false);
  const [ copyClicked, setCopyClicked] = useState(false);

  const notifications = useNotifications();
  const theme = useMantineTheme();
  theme.colorScheme = 'dark';

  const [ opened, handlers ] = useState(false);

  const clipboard = useClipboard({ timeout: 500 });

  const thumbnail = "https://cdn.boardsi.com/board-document/thumbnails/"+resume.id+".jpeg";

  const handleOpen = () => {
    navigate(`/app/builder/${resume.id}`);
  }

  const useStyles = createStyles( ( theme ) => ( {
    button: {
      borderRadius: 0,

      '&:not(:first-of-type)': {
        borderLeftWidth: 0,
      },

      '&:first-of-type': {
        borderTopLeftRadius: theme.radius.sm,
        borderBottomLeftRadius: theme.radius.sm,
      },

      '&:last-of-type': {
        borderTopRightRadius: theme.radius.sm,
        borderBottomRightRadius: theme.radius.sm,
      },
    },
    itemHovered: {
      backgroundColor: theme.colors.dark[ 4 ] + " !important",
    }
  } ) );

  const { classes } = useStyles();

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
    handlers((v)=>!v);
  };

  const handleDuplicate = () => {
    notifications.showNotification( {
      loading: true,
      title: "Duplicating Board Document",
      message: "Please wait while we duplicate your Board Document.",
      id: "boardDocDuplication"
    } );

    duplicateResume(resume);
  };

  const handleRename = () => {
    emitter.emit(events.CREATE_RESUME_MODAL, resume);
    setAnchorEl(null);
  };

  const handleDelete = () => {
    handlers(true)
    setAnchorEl( null );
  };

  const resumeTags = () => {
    console.log("");
  }

  const shareResume = () => {
    setOpenSharePop(true);
  }

  const deleteBoardDocument = () => {
    handlers( false );
    deleteResume( resume.id );

    const deleteNotification = notifications.showNotification( {
      loading: true,
      title: "Deleting Board Document",
      message: "Please wait while we delete your Board Document.",
    } );

    setTimeout( () => {
      notifications.updateNotification( deleteNotification, { deleteNotification, title: "Document Deleted!", color: "green" } );
    }, 3000 );
  }

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const bkg = Math.floor( ( Math.random() *  13 ) + 1 )

  /*
  const lol = {
    background: "url("+(resume.preview ? resume.preview : "")+")",
    backgroundSize: "cover"
  }
  */

  const lol = {
    background: "url("+thumbnail+")",
    backgroundSize: "cover"
  }

  return (
    <>
    <div className="body">
      <div className="card">
        <div className={styles.resume}>
          <div className={styles.backdrop} style={lol}/>
          <div className={styles.page}>
            <FileSymlink size={44} style={{color: "white"}} onClick={handleOpen}/>
            <Popover
            opened={openSharePop}
            position="bottom"
            placement="center"
            closeOnClickOutside
            trapFocus={true}
            onClose={()=>{setOpenSharePop(false)}}
            closeOnEscape={true}
            transition="slide-up"
            transitionDuration={200}
            target={<span style={{height:"0px !important"}}></span>}
            onClick={() => {setCopyClicked(true);clipboard.copy(`https://document.boardsi.com/r/${resume.id}`);setTimeout(()=>{setCopyClicked(false); setOpenSharePop(false);}, 2000)}}
            style={{cursor: "pointer !important"}}
            radius="sm"
            shadow="md"
            spacing="md"
          >
          <Tooltip allowPointerEvents openDelay={50} position="bottom" placement="center" label="Click to copy">
            <div style={{display: "flex"}}>
              { 
              copyClicked ? (
                <Check size={16} transition="fade" transitionDuration="250" style={{display:"inline-flex", color: "rgb(105, 219, 124)"}}/>
              ) : (
                <Share size={16} transition="fade" transitionDuration="250" style={{display:"inline-flex", color: "rgb(105, 219, 124)"}}/>
              )
              }
              &nbsp;&nbsp;https://document.boardsi.com/r/{resume.id}
              </div>
              </Tooltip>
            </Popover>
            <Menu zIndex="2080" gutter="5" size="lg" placement="center" control={
            <ActionIcon size={44} variant="transparent"><DotsCircleHorizontal style={{color: "white"}} size={44} /></ActionIcon>}>
              <Menu.Item onClick={handleRename} icon={<Certificate size={14} />}>Make Default Document</Menu.Item>
              <Menu.Item onClick={handleDuplicate} icon={<Copy size={14} />}>Duplicate</Menu.Item>
              <Menu.Item onClick={handleRename} icon={<EditCircle size={14} />}>Rename</Menu.Item>
                <Divider />

              <Menu.Item onClick={shareResume} icon={<Share size={14} />}>Share Link</Menu.Item>
              <Divider />

              <Menu.Item onClick={handleDelete} color="red" icon={<Trash size={14} />}>Delete Board Document</Menu.Item>
            </Menu>
          </div>
          <div className={styles.meta}>
            <span>{resume.name}</span>
            {resume.updatedAt && (
              <span>
                {t( 'dashboard.lastUpdated', {
                  timestamp: dayjs( resume.updatedAt )
                    .locale( i18n.language.substr( 0, 2 ) )
                    .fromNow(),
                } )}
              </span>
            )}
          </div>
        </div>
      </div>
    </div>
    <Dialog
        opened={opened}
        size="lg"
        radius="md"
      >
        <Text size="md" style={{ marginBottom: "1rem" }} weight={300}>
          Are you sure you want to delete {resume.name}?
        </Text>

        <Group position="apart" grow spacing={4}>
          <Button leftIcon={<Trash size={16}/>} variant="default" style={{ background: theme.colors.red[ 7 ] }} onClick={deleteBoardDocument} className={classes.button}>
            Delete
          </Button>
          <Button variant="default" style={{ background: theme.colors.gray[ 7 ] }} onClick={()=>handlers(false)} className={classes.button}>
            Cancel
          </Button>
        </Group>

      </Dialog>
      </>
  );
};

export default ResumePreview;

export { menuToggleDataTestIdPrefix };
